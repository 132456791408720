.overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    background-color: white;
    /*background-color: rgba(255,255,255, 0.9);*/
    overflow-x: hidden;
    transition: 0.5s;
    opacity: 0;
    
}
  
  .overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
  }
  
  .overlay a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: black;
    display: block;
    transition: 0.3s;
  }
  
  .overlay a:hover, .overlay a:focus {
    color: #b41010;
  }

  /* .overlay .contact {
    color: #b41010;
  } */
  
  .overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    
  }

span {
display: none;
font-size: 35px;

&:hover{
    color:#b41010;
}

    @media (max-width: 768px) {
        display: flex;
    }
    
  }
  
  @media screen and (max-height: 450px) {
    .overlay a {font-size: 20px}
    .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
    }
  }